import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { AddressDetails } from 'state/policy/state';
import { StyledParagraph } from './styles';

type AddressProps = { address: AddressDetails | null | undefined } & ComponentProps;

const Address: React.FC<AddressProps> = ({ address, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledParagraph data-cy="HirerAddress" {...componentProps(props)}>
      {address?.addressLine1}
      {address?.addressLine2 && (
        <>
          <br />
          {address.addressLine2}
        </>
      )}
      {address?.addressLine3 && (
        <>
          <br />
          {address.addressLine3}
        </>
      )}
      {address?.addressLine4 && (
        <>
          <br />
          {address.addressLine4}
        </>
      )}
      {address?.postCode && (
        <>
          <br />
          {address.postCode}
        </>
      )}
    </StyledParagraph>
  );
};

export default Address;
