import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { Location } from 'history';
import React from 'react';
import ConfirmAddressForm from 'components/Forms/ConfirmAddress';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import Layout from 'components/Layout';
import { useFormRedirect } from 'helpers/useFormRedirect';
import { DriverType } from 'types/forms';

export type ConfirmAddressData = {
  title: string;
  heading: string;
  rich_text: string;
};

type ConfirmAddressProps = {
  data: ConfirmAddressData;
  location: Location;
  driverType: DriverType;
};

const ConfirmAddressBaseComponent: React.FC<ConfirmAddressProps> = ({
  data,
  location,
  driverType,
}) => {
  useFormRedirect();
  return (
    <FormPageWrapper
      location={location}
      title={data.title}
      formPageName="confirmAddress"
      driverType={driverType}>
      <Layout pageType="form" meta={{ title: data.title }}>
        <FormHeading text={data.heading} />
        <RichText html={data.rich_text} />
        <ConfirmAddressForm driverType={driverType} />
      </Layout>
    </FormPageWrapper>
  );
};

export default ConfirmAddressBaseComponent;
