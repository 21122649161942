import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { ConfirmAddressState } from 'state/forms/confirmAddress/state';
import { CsDriverType, CsErrorsMissingOnly } from 'types/contentStack';
import { DriverType } from 'types/forms';

type CsDriverDetailsErrorMessages = {
  allCsAddDriverConfirmAddressQuestionsTemplate: {
    nodes: {
      driver_type: CsDriverType;
      is_address_correct: CsErrorsMissingOnly;
    }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverConfirmAddressQuestionsTemplate {
      nodes {
        driver_type
        is_address_correct {
          error_messages {
            missing
          }
        }
      }
    }
  }
`;

const useConfirmAddressRules = (driverType: DriverType): ValidationRules<ConfirmAddressState> => {
  const errorMessages = useStaticQuery<CsDriverDetailsErrorMessages>(query);
  const confirmAddressQuestions = errorMessages.allCsAddDriverConfirmAddressQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!confirmAddressQuestions) {
    throw new Error(`About the driver questions missing for ${driverType}`);
  }

  return {
    isAddressCorrect: [required(confirmAddressQuestions.is_address_correct.error_messages.missing)],
  };
};

export default useConfirmAddressRules;
