import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { navigate } from 'gatsby';
import React from 'react';
import { useErrorState } from 'state/error/actions';
import { Knockout } from 'state/error/state';
import { useConfirmAddress } from 'state/forms/confirmAddress/action';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { usePolicyState } from 'state/policy/state';
import BooleanRadioInput from 'components/BooleanRadioInput';
import FormFooter from 'components/Forms/FormFooter';
import QuestionField from 'components/QuestionField';
import { trackFieldError, trackNamedDriverFormContinue } from 'helpers/eventTracking';
import { formNameStepMapping } from 'helpers/formNameStepMapping';
import { scrollAndTrackError } from 'helpers/forms';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import { DriverType } from 'types/forms';
import useConfirmAddressQuestions from './questions';
import { StyledAddress } from './styles';
import useConfirmAddressRules from './validation';

const ConfirmAddressForm: React.FC<{ driverType: DriverType }> = ({ driverType }) => {
  const { policy } = usePolicyState();
  const [, updateErrorState] = useErrorState();
  const [confirmAddress, updateConfirmAddress] = useConfirmAddress();
  const rules = useConfirmAddressRules(driverType);
  const questions = useConfirmAddressQuestions(driverType);
  const { getError, validateOnSubmit } = useValidation(confirmAddress, rules, trackFieldError);
  const [replaceDriverState] = useReplaceDriver();

  const handleSuccess = (): void => {
    trackNamedDriverFormContinue(
      {
        formPage: "Policyholder's address",
        formStep: formNameStepMapping.confirmAddress,
        correctAddress: confirmAddress.isAddressCorrect,
      },
      replaceDriverState.replacedDriverRef
    );
    if (confirmAddress.isAddressCorrect) {
      navigate(getFormPageRoute(driverType, FormPage.DriverConsent));
    } else {
      updateErrorState({ hasErrored: true, knockout: Knockout.HIRER_ADDRESS_INCORRECT });
    }
  };

  return (
    <form onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
      <StyledAddress address={policy?.personalDetails.address} />
      <QuestionField question={questions.isAddressCorrect} errorText={getError('isAddressCorrect')}>
        <BooleanRadioInput
          id="isAddressCorrect"
          value={confirmAddress.isAddressCorrect}
          onChange={(newValue) => {
            updateConfirmAddress({ isAddressCorrect: newValue });
          }}
        />
      </QuestionField>
      <FormFooter
        backButton={{
          onClick: () => navigate(getFormPageRoute(driverType, FormPage.BeforeYouStart)),
        }}
      />
    </form>
  );
};

export default ConfirmAddressForm;
