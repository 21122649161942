import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledParagraph = styled.p`
  ${fonts.paragraph};

  && {
    margin-bottom: ${spacing(4)};
  }
`;
