import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import Address from 'components/Address';

const slightlyBoldFontWeight = 500;

export const StyledAddress = styled(Address)`
  ${fonts.paragraphLarge}
  & {
    font-weight: ${slightlyBoldFontWeight};
  }

  margin: ${spacing(4)} 0 ${spacing(4)} 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(1)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;
