import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsDriverType, CsQuestion } from 'types/contentStack';
import { DriverType, Question } from 'types/forms';

export type ConfirmAddressQuestions = {
  isAddressCorrect: Question;
};

type CsConfirmAddressQuestions = {
  allCsAddDriverConfirmAddressQuestionsTemplate: {
    nodes: {
      driver_type: CsDriverType;
      is_address_correct: CsQuestion;
    }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverConfirmAddressQuestionsTemplate {
      nodes {
        driver_type
        is_address_correct {
          main_details {
            question_text
            explanatory_text
            tooltip {
              body
              button_text {
                open
                close
              }
            }
            alert_text
          }
        }
      }
    }
  }
`;

const useConfirmAddressQuestions = (driverType: DriverType): ConfirmAddressQuestions => {
  const { allCsAddDriverConfirmAddressQuestionsTemplate } = useStaticQuery<
    CsConfirmAddressQuestions
  >(query);
  const { processQuestion } = useQuestionProcessor();
  const confirmAddressQuestions = allCsAddDriverConfirmAddressQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!confirmAddressQuestions) {
    throw new Error(`Confirm address questions missing for ${driverType}`);
  }

  return {
    isAddressCorrect: processQuestion(confirmAddressQuestions.is_address_correct),
  };
};

export default useConfirmAddressQuestions;
